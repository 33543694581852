.price-refinement {
    .noUi-handle {
        box-shadow: none;
    }

    #priceSlider--link {
        padding-right: 10px;
        font-size: 12px;
        font-weight: 500;
        padding-top: 5px;
    }

    .noUi-handle.noUi-handle-lower {
        width: 10px;
        height: 10px;
        border-radius: 50px;
        border-width: 2px;
        border-color: #1e1e1e;
        background: #1e1e1e;
        top: -5px;
    }

.price-refinement-tooltip {
        .right-side {
            float: right;
            width: fit-content;
            padding-right: 13px;

        }
    }
}

.price-refinement {
    .noUi-handle.noUi-handle-upper {
        width: 10px;
        height: 10px;
        border-radius: 50px;
        border-width: 2px;
        border-color: #1e1e1e;
        background: #1e1e1e;
        top: -5px;
    }
}

.noUi-horizontal {
    .noUi-handle {
        right: -1px;
    }
}

#priceslider {
    height: 3px;
    width: 96%;
    background-color: #1e1e1e;
    padding-left: 7px;
    margin: auto;
    margin-top: 5px;
    margin-left: 3px;
}

.price-value {
    padding: 10px 0 2px 0;
}

.noUi-base {
    .noUi-connects {
        .noUi-connect {
            background-color: #1e1e1e;
        }
    }
}

.noUi-handle {
    &:before {
        background-color: rgba(0, 0, 0, 0);

    }

    &:after {
        background-color: rgba(0, 0, 0, 0);
    }
}
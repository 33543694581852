.refinement__color,
.refinement__size {
    display: flex;
    flex-wrap: wrap;
    padding-left: 0px !important;
}

.refinement__list {
    .refinement__list {
        padding-left: 1rem;
    }

    &:not(:first-child) {
        margin-top: 1.125rem;
    }
}

.refinement__list-item {
    &:not(:last-child) {
        margin-bottom: 1.15em;
    }

    a.maincategory {
        &.searchResultPage {
            display: block;
        }
    }

    a.searchResultPage {
        display: none;
    }

    .form-check-label {
        font-weight: 400;

        &.set--checked {
            font-weight: 600;
        }
    }

    &.set--categories {
        a.searchResultPage {
            display: block;
        }
    }
}

.refinement__boolean-item {
    &:not(:last-child) {
        margin-bottom: 1rem;
    }
}

.refinement__chip-item {
    margin-right: .625rem;
}

.refinement__chip-item,
.refinement__swatch-color-item {
    margin-bottom: 10px;
    width: 50%;

    .icon {
        fill: currentColor;
        width: 11px;
        height: 11px;
        box-shadow: 0px 0px 3px 0px #b9b9b9;
        background-color: transparent;
        border-radius: 50%;
    }

    .swatch--color {
        display: flex;
        position: relative;
        border: none;
        background: transparent;

        .refinement-color-name {
            position: absolute;
            left: 30px;
            font-size: 12px;
            font-weight: 500;
            text-transform: capitalize;
            color: #908C88;

            &:last-child {
                padding-bottom: 0px !important;
            }
        }

        &.selected {
            cursor: pointer;
            pointer-events: auto;

            .icon {
                padding: 1px;
                border: 1px solid #1E1E1E;
                width: 14px;
                height: 14px;
                border-radius: 50%;
            }

            .refinement-color-name {
                color: #1E1E1E;
                font-weight: 600;
            }
        }
    }
}

.applied--refinement-wrapper {
    padding-left: 5px;
    padding-right: 5px;
}

.applied-refinements__list {
    justify-content: space-between;
    align-items: center;
    padding-top: 10px;
    padding-bottom: 10px;

    .applied-refinements__item_wrapper {
        display: flex;
        gap: 10px;
    }
}

.filter-category-selected {
    font-size: 12px;
    font-weight: 500;
}

.applied-refinements__item {
    padding-top: 15px;
    padding-bottom: 5px;

    .icon-close{
        background-image: url('../images/crossVectorFilter.png');
        display: block;
        position: relative;
        height: 8px;
        width: 8px;
        margin-left: 10px;
        background-repeat: no-repeat;
        background-size: 8px;
    }

    &:not(:last-child) {
        margin-right: 0;
        margin-bottom: 0;
    }

    &.refinement-bar__reset {
        font-weight: 500;
        padding-top: 15px;
        padding-bottom: 5px;

        button {
            padding-top: 0;
        }
    }
}

.search-results__toolbar {
    @media (max-width: 992px){
        padding-right: 0;
    }
    .search-results__toolbar-count {
        font-weight: 500;
    }
}


.plp-filter-popup {

    .refinement-bar {
        position: relative;
    }

    .refinement-bar__body {
        padding-top: 1.75rem;
    }

    .refinement {
        padding-bottom: 1.25rem;
        margin-bottom: 0;
        border-bottom: none;

        &.refinement-size {
            .refinement__content {
                .list-items {
                    display: flex;
                    padding: 0px !important;

                    .refinement__list-types {
                        width: max-content;
                        text-align: center;
                        min-width: 13%;
                    }

                    .form-check-label {
                        padding: 2px 8px;
                        border: 1px solid #908C88;
                        line-height: 20px;
                        font-size: 12px;
                        color: #908C88;

                        &::before {
                            display: none;
                        }

                        &::after {
                            display: none;
                        }

                        &.set--checked {
                            color: #ffffff !important;
                            background: #1E1E1E;

                            &::after {
                                display: none;
                            }
                        }
                    }
                }
            }
        }

        // &.refinement-category {
        //     position: absolute;
        //     top: -100%;
        // }

        .refinement__content > .list-items{
                padding-left: 0px !important;
        }

        .list-items {
            .form-check-label {
                color: #908C88;
                font-weight: 500;
                font-size: 12px;

                &:hover {
                    text-decoration: none;
                }

                &::after {
                    top: 2px;
                    left: 0.2em;
                    width: 10px;
                    height: 10px;
                    border: 1px solid #1e1e1e !important;
                    border-radius: 0px;
                }

                &::before {
                    height: 15px;
                    width: 15px;
                    border: 1px solid #1e1e1e;
                    border-radius: 0px;
                }
            }
        }

        &.refinement-price{
            &.refinement--active{
                padding: 0px;
            }
        }
    }

    .refinement__content {
        margin-top: 1.5rem;
    }

    .applied-refinements__list {
        margin-top: 1.25rem;
        margin-bottom: 1.25rem;
    }
}

.refinement-bar__title {
    margin-bottom: 0;

    &.plp-filter-btn {
        font-size: 12px;
        font-weight: 500;
        cursor: pointer;
        display: flex;
        width: fit-content;

        @media (min-width:768px) and (max-width:1024px){
            padding: 10px 0px;
        }

        @media (max-width:767px) {
            font-size: 12px;
        }

        .vector-icon {
            &.vector-right-arrow {
                background-image: url('../images/downArrow.png');
                display: block;
                position: relative;
                height: 15px;
                width: 22px;
                transform: rotate(273deg);
                background-repeat: no-repeat;
                background-size: 7px;
                background-position: 6px 6px;
            }
        }
    }
}

.search-results__toolbar{
    @media (min-width:768px) and (max-width:1024px){
        padding: 10px 0px;
    }
}


.refinement__size.list--reset {
    display: block;
}


.refinement__content {
    .swatch--color-large {
        width: 1.313rem;
        height: 1.313rem;
    }
}

.swatch--color- {

    &beige,
    &ecru {
        color: #F5F5DC;
    }

    &black {
        color: #000000;
    }

    &blue,
    &darkblue,
    &electricblue,
    &iceblue,
    &indigoblue,
    &inkblue,
    &lightblue,
    &midnightblue,
    &navyblue,
    &peacockblue,
    &powderblue,
    &royalblue,
    &seablue,
    &skyblue,
    &turqoiseblue,
    &aqua,
    &denim,
    &turquoise,
    &slate {
        color: #1979D9;
    }

    &brown,
    &darkbrown,
    &brick,
    &bronze,
    &camel,
    &coffee,
    &copper,
    &khaki,
    &rust,
    &sand,
    &tan {
        color: #964B00;
    }

    &gold {
        color: #FFD700;
    }

    &green,
    &darkgreen,
    &emerald,
    &emeraldgreen,
    &limegreen,
    &pistagreen,
    &sagegreen,
    &seagreen,
    &mint,
    &olive,
    &teal {
        color: #15C515;
    }

    &grey,
    &darkgrey,
    &lightgrey,
    &charcoal,
    &gunmetal,
    &mouse {
        color: #696969;
    }

    &maroon,
    &berry,
    &burgundy,
    &cherrywood,
    &cranberry,
    &plum,
    &rasberry,
    &wine {
        color: #800000;
    }

    &orange,
    &apricot,
    &burntorange,
    &citrus,
    &coral,
    &flame,
    &tangerine {
        color: #FFA500;
    }

    &peach,
    &peachpuff,
    &flesh,
    &natural,
    &nude {
        color: #FFAF94;
    }

    &pink,
    &darkpink,
    &hotpink,
    &lightpink,
    &onionpink,
    &rosegold,
    &rosepink,
    &whiteflesh,
    &blush,
    &fuschia,
    &magenta,
    &oldrose,
    &rosewood {
        color: #FD82C6;
    }

    &purple,
    &lilac,
    &taupe {
        color: #B152ED;
    }

    &red,
    &red,
    &redshaded,
    &tomato,
    &crimson,
    &ruby {
        color: #DE3232;
    }

    &silver {
        color: #C0C0C0;
    }

    &white,
    &cream,
    &creamlime,
    &champagne,
    &ivory,
    &offwhite {
        color: #ffffff;
    }

    &yellow,
    &mango,
    &mustard,
    &ochre {
        color: #FFEF00;
    }

    &lime {
        color: #BCEE00;
    }

    &indigo {
        color: #61008B;
    }

    &aqua {
        color: #00C6E1;
    }

    &aquagreen {
        color: #15C515;
    }
}
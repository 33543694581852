.list-view-switch__trigger:not(.set--active) {
    color: black;

}

// .search-results{
//     @media (max-width:767px) {
//         padding-left: 15px;
//         padding-right: 5px;
//     }
// }

.two-product-layout,
.four-product-layout {
    .vector-icon {
        display: block;
        position: relative;
        height: 15px;
        width: 25px;
        background-repeat: no-repeat;
    }
}

.two-product-layout {
    .vector-icon {
        &.vector-not-active {
            background-image: url('../images/twoProduct.png');
            background-size: 10px;

            @media (max-width:992px) {
                background-image: url('../images/oneProduct.png') !important;
                background-size: 6px;
            }
        }

        &.vector-active {
            background-image: url('../images/twoProduct-active.png');
            background-size: 10px;

            @media (max-width:992px) {
                background-image: url('../images/oneProduct-active.png') !important;
                background-size: 6px;
            }
        }
    }
}

.four-product-layout {
    .vector-icon {
        &.vector-not-active {
            background-image: url('../images/fourProduct.png');
            background-size: 20px;

            @media (max-width:992px) {
                background-image: url('../images/twoProduct-mobile.png') !important;
                background-size: 13px;
            }
        }

        &.vector-active {
            background-image: url('../images/fourProduct-active.png');
            background-size: 20px;

            @media (max-width:992px) {
                background-image: url('../images/twoProduct-active-mobile.png') !important;
                background-size: 13px;
            }
        }
    }
}

.filter-popup-overlay{
    position: fixed;
    z-index: 11;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    background: rgba(0, 0, 0, 0.5);
    visibility: hidden;
    opacity: 0;
    transition: visibility 0s linear 0.3s, opacity 0.3s;
    cursor: pointer;

    &.overlay-active{
        visibility: visible;
        opacity: 1;
        transition-delay: 0s;
    }
}

.plp-filter-popup {
    position: fixed;
    top: 0;
    z-index: 111;
    background: white;
    left: 0;
    height: 100vh;

    .plp-filter-popup-content{
        padding-top: 30px;
        width: 413px;
        height: 100vh;
        max-height: 100%;
        position: relative;
        @media (max-width:992px) {
            width: 100% !important;
            padding-top: 20px;
            height: 100vh;
        }
    }
    @media (max-width:992px) {
        width: 100% !important;
    }


    .plp-filter-close-btn {
        display: block;
        position: absolute;
        right: 38px;
        top: 30px;
        height: 10px;
        width: 22px;
        background-repeat: no-repeat;
        background-size: 12px 12px;
        background-position: 4px 37px;
        background-image: url('../images/crossVectorFilter.png');
        cursor: pointer;
        padding-bottom: 80px;
        @media (min-width: 768px) and (max-width: 1024px) {
            right: 25px;
        }
        @media (max-width: 767px) {
            right: 30px !important;
        }
    }

    .plp-filter-wrapper {
        margin-top: 80px;
        padding: 0px 30px;
        height: calc(100vh - 185px);
        max-height: calc(100vh - 185px);
        overflow-x: hidden;
        overflow-y: auto;

        @media (max-width: 1024px) {
            padding: 0px 20px;
            height: calc(100vh - 270px);
            max-height: calc(100vh - 270px);
        }

        @media (max-width: 992px) {
            padding: 0px 20px;
            height: calc(100vh - 250px);
            max-height: calc(100vh - 250px);
        }
        @media (max-width: 768px) {
            padding: 0px 20px;
            height: calc(100vh - 280px);
            max-height: calc(100vh - 280px);
        }
        &::-webkit-scrollbar {
            width: 6px;
        }

        &::-webkit-scrollbar-track {
            background: rgb(196, 196, 196);
            border-radius: 6px;
        }

        &::-webkit-scrollbar-thumb {
            background: rgb(129, 129, 129);
            border-radius: 6px;
        }
    }

    .plp-filter-actions {
        box-shadow: 0px -2px 4px 0px rgba(0, 0, 0, 0.03);
        padding:20px;
        background: white;
    }


    // .plp-filter-actions {
    //     position: absolute;
    //     bottom: 0;
    //     padding: 30px;
    //     width: -webkit-fill-available;
    //     left: 0;
    //     box-shadow: 0px -2px 4px 0px rgba(0, 0, 0, 0.03);
    // }
}

.plp__top-level-category-wrapper {
    text-align: center;

    .plp__top-level-category-text {
        font-size: 16px;
        font-weight: 500;

        @media (max-width:767px) {
            font-size: 14px;
        }

        span {
            display: none;

            &:last-child {
                display: block;
            }
        }
    }

    .universal-category {
        padding: 10px 0px 30px;
        margin: 0;
        display: flex;
        justify-content: center;
        gap: 32px;

        @media (max-width:767px) {
            gap: 20px;
            flex-wrap: wrap;
        }

        .uni-cat-list {
            list-style: none;

            .attributeLink {
                font-size: 12px;
                font-weight: 500;
                cursor: pointer;
                color: #908C88;

                &.selected {
                    color: #1E1E1E;
                    cursor: default;
                }
            }
        }
    }
}


.hamburger-open{
    .filter-bar-wrapper{
        &.sticky{
            z-index: 11;
        }
    }
}


.filter-bar-wrapper {
    width: 100%;
    margin: auto !important;
    // @media (max-width:992px) {
    //     padding-left: 15px;
    // }
    @media (max-width:767px) {
        padding-left: 12px;
        padding-right: 7.4px;
    }
    @media (min-width:992px) {
        .filter-bar-row{
            width: 100%;
            margin: auto;
        }
    }

    @media (max-width:767px) {
        padding-bottom: 20px;

    }

    .filter-btn-wrapper {
        padding-left: 0;
    }

    &.sticky {
        position: fixed;
        top: 95px;
        padding-top: 15px;
        background: white;
        width: 100%;
        left: 0;
        z-index: 2;
        max-width: 100%;
        @media (max-width:767px) {
            top: 90px;
            padding-left: 32px;
            padding-right: 27px;
            .filter-bar-row {
                padding-left: 0;
                padding-right: 0;
            }
        }
        @media  (min-width:992px) {
            .filter-bar-row {
                padding-right: 42px;
            }
            .filter-btn-wrapper {
                padding-left: 10px;
            }
        }

        // .filter-bar-row {
        //     margin: auto;
        //     width: 1380px;
        //     padding-right: 18px;

        //     @media (max-width:1025px) {
        //         width: auto;
        //         padding-right: 25px;
        //         padding-left: 20px;
        //     }

        //     @media (max-width:767px) {
        //         padding-right: 20px;
        //         padding-left: 15px;
        //     }

        //     @media (min-width:1159px) and (max-width:1320px) {
        //         padding-right: 174px;
        //         padding-left: 69px;
        //     }
        //     @media (min-width:1321px) and (max-width:1400px) {
        //         padding-right: 87px;
        //         padding-left: 73px;
        //     }
        //     @media (min-width:1400px) and (max-width:1440px) {
        //         padding-right: 50px;
        //         padding-left: 50px;
        //     }
        // }
    }
}

.plp-getTheLook-section {
    margin-bottom: 2.25rem;

    @media (min-width:1025px) {
        margin-bottom: 5.438rem
    }

    .getTheLookwrap {
        align-items: center;

        .product-heading {
            font-size: 20px;
            font-weight: 500;

            @media (max-width:767px) {
                font-size: 14px;
                padding-top: 20px;
            }
        }

        .product-details {
            @media (min-width:1025px) {
                padding-left: 70px;
            }
        }

        .product-description {
            font-weight: 500;
            font-size: 12px;
            line-height: 22px;
            padding-top: 30px;
            padding-bottom: 30px;
            margin-bottom: 15px;
            border-bottom: 0.5px solid #908C88;
            @media (min-width: 768px) and (max-width: 992px) {
                padding-top: 20px;
                padding-bottom: 20px;
            }
            @media (max-width:767px) {
                font-size: 11px;
                line-height: 20px;
                padding-top: 20px;
                padding-bottom: 10px;
                border-bottom: none;
            }

        }

        .product-name {
            font-size: 12px;
            font-weight: 500;
        }

        .product-price {
            font-size: 12px;
            font-weight: 600;
            line-height: 26px;
        }
    }
}

.list-view-switch__icon--selected {
    display: none;

    .set--active & {
        display: block;
    }
}

.list-view-switch__icon--unselected {
    .set--active & {
        display: none;
    }
}